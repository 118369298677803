import React from "react";
import { Helmet } from "react-helmet";
import classnames from "classnames";

import { PageLayout } from "../layouts";
import { Block, TrackedLink } from '../components';

import Wizard from "../images/wizard.svg";

import "../global/styles/page-styles/brand-page.scss";

/**
 *
 * Asset arrays.
 *
 */
const PRIMARY_LOGOS = [
  {
    pngSrc:
      "https://cdn.partie.com/downloads/assets/images/downloadables/partie_full_gray.png",
    svgSrc:
      "https://cdn.partie.com/downloads/assets/images/downloadables/partie_full_gray.svg",
  },
  {
    pngSrc:
      "https://cdn.partie.com/downloads/assets/images/downloadables/partie_full_blue.png",
    svgSrc:
      "https://cdn.partie.com/downloads/assets/images/downloadables/partie_full_blue.svg",
  },
  {
    pngSrc:
      "https://cdn.partie.com/downloads/assets/images/downloadables/partie_full_white.png",
    svgSrc:
      "https://cdn.partie.com/downloads/assets/images/downloadables/partie_full_white.svg",
    bordered: true,
  },
];

const ALT_ICON_LOGOS = [
  {
    pngSrc:
      "https://cdn.partie.com/downloads/assets/images/downloadables/partie_logo_gray.png",
    svgSrc:
      "https://cdn.partie.com/downloads/assets/images/downloadables/partie_logo_gray.svg",
    bordered: true,
  },
  {
    pngSrc:
      "https://cdn.partie.com/downloads/assets/images/downloadables/partie_logo_blue.png",
    svgSrc:
      "https://cdn.partie.com/downloads/assets/images/downloadables/partie_logo_blue.svg",
    bordered: true,
  },
  {
    pngSrc:
      "https://cdn.partie.com/downloads/assets/images/downloadables/partie_logo_white.png",
    svgSrc:
      "https://cdn.partie.com/downloads/assets/images/downloadables/partie_logo_white.svg",
    bordered: true,
  },
];

const ALT_TEXT_LOGOS = [
  {
    pngSrc:
      "https://cdn.partie.com/downloads/assets/images/downloadables/partie_gray.png",
    svgSrc:
      "https://cdn.partie.com/downloads/assets/images/downloadables/partie_gray.svg",
  },
  {
    pngSrc:
      "https://cdn.partie.com/downloads/assets/images/downloadables/partie_blue.png",
    svgSrc:
      "https://cdn.partie.com/downloads/assets/images/downloadables/partie_blue.svg",
  },
  {
    pngSrc:
      "https://cdn.partie.com/downloads/assets/images/downloadables/partie_white.png",
    svgSrc:
      "https://cdn.partie.com/downloads/assets/images/downloadables/partie_white.svg",
  },
];

const PARTIE_COLORS = [
  {
    colorName: "Ornery Gray",
    hexValue: "#2E2E49",
  },
  {
    colorName: "Canoodle Blue",
    hexValue: "#6E4AFF",
  },
  {
    colorName: "Brouhaha White",
    hexValue: "#FFFFFF",
    bordered: true,
  },
];

/**
 *
 * Brand page function.
 * @returns page
 *
 */
const BrandPage = () => {
  return (
    <>
      <Helmet>
        <title>Partie | Brand</title>
        <html lang="en" />
        <meta name="description" content="Clear, practical guidelines on how to use Partie's core brand
              elements." />
      </Helmet>
      <PageLayout className="brand-page">
        <Block hero lazy>
          <div className="partie-block__column">
            <h2 className="partie-block__title">Partie Brand</h2>
            <p className="partie-block__description">
              Below are clear, practical guidelines on how to use our core brand
              elements. Please{" "}
              <TrackedLink type="inline-link" to="/contact" children="contact us" />
              {" "}
              to receive approval and we’ll be happy to help you out.
            </p>
          </div>
          <div className="partie-block__column" aria-hidden>
            <img src={Wizard} alt="wizard" aria-hidden />
          </div>
        </Block>
        <Block className="brand-page__assets-block" centered>
          <h3 className="partie-block__title">Primary Logo</h3>
          <p className="partie-block__description">
            Please do not edit, change, crop, distort, recolor, stretch, stack
            or redesign the Partie logo in any capacity.
          </p>
          <div className="brand-page__assets">
            {PRIMARY_LOGOS.map((asset, index) => (
              <div
                className="brand-page__assets-col"
                key={`${asset.type}-${index}`}
              >
                <BrandAsset data={asset} type="Primary" />
              </div>
            ))}
          </div>
        </Block>
        <Block className="brand-page__assets-block" centered tinted>
          <h3 className="partie-block__title">Alternate Logos</h3>
          <p className="partie-block__description">
            Use these only when the Partie brand is clearly visible or has been
            well established elsewhere on the page or in the design.
          </p>
          <div className="brand-page__assets">
            {ALT_ICON_LOGOS.map((asset, index) => (
              <div
                className="brand-page__assets-col"
                key={`${asset.type}-${index}`}
              >
                <BrandAsset data={asset} type="Alternate" isIcon />
              </div>
            ))}
          </div>
          <div className="brand-page__assets">
            {ALT_TEXT_LOGOS.map((asset, index) => (
              <div
                className="brand-page__assets-col"
                key={`${asset.type}-${index}`}
              >
                <BrandAsset data={asset} type="Alternate" />
              </div>
            ))}
          </div>
        </Block>
        <Block className="brand-page__assets-block" centered>
          <h3 className="partie-block__title">Partie Colors</h3>
          <p className="partie-block__description">
            Please only use our approved colors for logo and background
            applications.
          </p>
          <div className="brand-page__assets">
            {PARTIE_COLORS.map((asset, index) => (
              <div
                className="brand-page__assets-col"
                key={`${asset.type}-${index}`}
              >
                <BrandAsset data={asset} type="Color" />
              </div>
            ))}
          </div>
        </Block>
      </PageLayout>
    </>
  );
};

/**
 *
 * Returns a single asset.
 * @param {data: obj, isIcon: boolean, type: 'Primary' | 'Alternate' | 'Color' } param0
 * @returns ReactElemeent
 *
 */
const BrandAsset = ({ data, isIcon, type }) => {
  const { colorName, bordered, hexValue, pngSrc, svgSrc } = data;

  const copyHexClickHandler = (hexValue) => {
    navigator.clipboard.writeText(hexValue);
  };

  return (
    <div className="brand-asset">
      <div
        className={classnames("brand-asset__container", {
          "brand-asset__container--bordered": bordered,
          "brand-asset__container--color": hexValue && colorName,
          "brand-asset__container--dark": hexValue !== "#FFFFFF",
          "brand-asset__container--icon": isIcon,
        })}
        style={{
          backgroundColor: hexValue,
        }}
      >
        {pngSrc || svgSrc ? (
          <img src={pngSrc} alt={`Partie ${type} Logo`} loading="lazy" />
        ) : (
          colorName &&
          hexValue && (
            <>
              <h4 className={classnames("brand-asset__color-name")}>
                {colorName}
              </h4>
              <span className="brand-asset__color-hex">{hexValue}</span>
            </>
          )
        )}
      </div>
      <div className="brand-asset__actions">
        {svgSrc && (
          <a className="inline-link" href={svgSrc} title="Download .svg">
            .svg
          </a>
        )}
        {pngSrc && (
          <a className="inline-link" href={pngSrc} title="Download .png">
            .png
          </a>
        )}
        {hexValue && (
          <button
            className="inline-link"
            onClick={() => copyHexClickHandler(hexValue)}
            title="Copy HEX value"
          >
            Copy Hex
          </button>
        )}
      </div>
    </div>
  );
};

export default BrandPage;